<template>
    <q-dialog ref="dialogRef" @hide="onDialogHide" :persistent="persistent">
        <q-card class="q-dialog-plugin">
            <div class="dialog-title">{{ $t(title) }}</div>
            <div style="padding: 0 16px 16px;">
                <div v-for="(item, index) in messages" :key="index">
                    <div>{{ $t(item) }}</div>
                </div>
            </div>
            <q-card-actions align="right">
                <q-btn v-if="showCancel" color="#fff" :label="$t('取消')" @click="onDialogCancel" :flat="true" />
                <q-btn color="#fff" :label="$t('确定')" @click="onOKClick" :flat="true" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useDialogPluginComponent } from 'quasar'

const props = defineProps({
    title: {
        type: String,
        default: "提示"
    },
    messages: {
        type: Array,
        default() {
            return ["确认操作吗？"]
        }
    },
    persistent: {
        type: Boolean,
        default: false
    },
    showCancel: {
        type: Boolean,
        default: false
    }
})

defineEmits([
    // 必需；需要指定一些事件
    //（组件将通过useDialogPluginComponent()发出）
    ...useDialogPluginComponent.emits
])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
// dialogRef - 应用于QDialog的Vue引用。
// onDialogHide - 用作QDialog上@hide的处理函数。
// onDialogOK - 调用函数来处理结果为"确定"的对话框。
//              例子: onDialogOK() - 没有有效载荷
//              例子: onDialogOK({ /*...*/ }) -- 有有效载荷
// onDialogCancel - 调用函数来处理结果为"取消"的对话框。

// 这是我们例子的一部分（所以不是必须的）。
function onOKClick() {
    // 在"确定"时，它必须要 
    // 调用onDialogOK（带可选的有效载荷）。
    onDialogOK()
    // 或使用有效载荷：onDialogOK({ ... })    
    // ...它还会自动隐藏对话框
}
</script>

<style scoped>
.q-dialog-plugin {
    max-height: calc(100vh - 108px);
    /* background: #000;
    color: #fff;
    border: 2px solid #f56ef9; */
}

.dialog-title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    padding: 16px;
}
</style>